import SearchProviderInterface from "./SearchProviderInterface";

export default class TextSearchProvider extends SearchProviderInterface {
    constructor(viewer) {
        super(viewer);
        viewer.loadExtension('Autodesk.StringExtractor');
    };

    /**
     * Function returns result that contains filtered by search query list of strings
     * @param {string} searchQuery - search query for String search
     * @returns {Promise} Promise with array of strings for each model.
     */
    search(searchQuery) {
        return new Promise ((resolve) => {
            this.viewer.getExtensionAsync('Autodesk.StringExtractor').then((ext) => {
                return ext.getDocumentStrings().then((documentStrings) => {
                    const result = [];
    
                    Object.keys(documentStrings).forEach((key) => {
                        const extractedStrings = documentStrings[key].strings;
                        const searchResult = this.searchStrings(extractedStrings, searchQuery);
                        result.push({
                            modelId: key,
                            searchResult: searchResult
                        });
                    });
                    resolve(result);
                });
            });
        });
    };

    /**
     * Functions performs search on a given data by filtering with searchQuery
     * @param {*} data data to search in
     * @param {*} searchQuery filtering string from user's input
     */
    searchStrings(data, searchQuery) {
        const searchResult = [];
        for (let i = 0; i < data.length; i++) {
            if (data[i].string.toLowerCase().indexOf(searchQuery) !== -1) {
                searchResult.push(data[i]);
            }
        }
        return searchResult;
    };

    /**
     * @returns {string} Name of Search Provider
     */
    getProviderId() {
        return 'TextSearchProvider';
    };
};
